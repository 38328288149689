import { Routes } from "@angular/router";
import { PagePlanGroupComponent } from "./plan-group-page.component";


export const routes: Routes = [
  { path: 'group', component: PagePlanGroupComponent },
  // {
  //   path: 'my',
  //   component: MyPlanComponent,
  //   canActivate: [canNotActivateWithRoles(['CMF_HRP_INFO', 'CMF_HRP_WORKS_COUNCIL'])],
  // },
  { path: '', redirectTo: 'group', pathMatch: 'full' },
]
