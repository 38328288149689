import {
  Component,
  ChangeDetectionStrategy,
  inject,
  DestroyRef,
  Output,
  EventEmitter,
  computed,
  effect,
  untracked,
  signal,
  WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import {
  ContributorInfoDto,
  ContributorScheduleDto,
  OrganizationalUnitDto,
  QuerySettingsDto,
  ScheduleItemInfoDto,
} from '@swagger/humanresources';
import { combineLatest, debounceTime, firstValueFrom, map } from 'rxjs';
import { ResponsiveService } from '@core/services';
import { CalendarEntry, CalendarMode } from '@shared/plan-defs';
import { DateAdapter, WeekDay } from '@paragondata/ngx-ui/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ComponentStore } from '@ngrx/component-store';
import { PlanGroupStore } from '../store/plan-group/plan-group.store';
import { isEmpty, isEqual } from 'lodash';
import { DateService } from '@shared/utils';
import { BreakTimeService } from '@shared/break-time';
import {
  CalendarFilterService,
  CalendarGroupBodyDayComponent,
  CalendarGroupBodyMonthComponent,
  CalendarGroupBodyWeekComponent,
  CalendarGroupFilterComponent,
  CalendarGroupNavigationComponent,
  ScrollableDirective,
} from '../shared';
import { SessionService } from '@core/auth';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { ParProgressModule } from '@paragondata/ngx-ui/progress';
import { FilterOverlayComponent } from '@shared/ui';
import {
  CalendarNavigationMobileWeekComponent,
  CalendarStepperComponent,
} from '@shared/plan-navigation';
import { Filter, IFilter } from '@paragondata/ngx-ui/filter';
import { HorizontalScrollComponent } from '@shared/horizontal-scroll';
import { ChipComponent } from '@shared/chip';
import moment from 'moment';

export interface PagePlanGroupState {
  loading: boolean;
  entries: { [key: string]: CalendarEntry[] };
  schedules: ContributorScheduleDto[];
  employees: ContributorInfoDto[];
  date: Date;
  mode: CalendarMode;
  visibleMonthCompactCalendar: Date;
  firstDayOfWeek: Date;
  lastDayOfWeek: Date;
  scheduleId: string;
  filterActive: boolean;
  queryParams: Params;
  datelocked: boolean;
  skipForRange: { [key: string]: number };
}

@Component({
  selector: 'page-plan-group',
  templateUrl: 'plan-group-page.component.html',
  styleUrls: ['my-plan-page.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    CalendarGroupFilterComponent,
    CalendarGroupBodyMonthComponent,
    CalendarGroupBodyDayComponent,
    CalendarGroupBodyWeekComponent,
    CalendarNavigationMobileWeekComponent,
    CalendarStepperComponent,
    TranslocoModule,
    CalendarGroupNavigationComponent,
    ParProgressModule,
    FilterOverlayComponent,
    HorizontalScrollComponent,
    ChipComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PagePlanGroupComponent extends ComponentStore<PagePlanGroupState> {
  private destroyRef = inject(DestroyRef);
  @Output() employeeListChanged: EventEmitter<void> = new EventEmitter<void>();
  calendarGroupBreakTimeDayService = inject(BreakTimeService);
  calendarFilterService = inject(CalendarFilterService);
  private _session = inject(SessionService);

  private store = inject(PlanGroupStore);
  private entries$ = this.select((s) => s.entries);
  private employees$ = this.select((s) => s.employees);
  private schedules$ = this.select((s) => s.schedules);
  private loading$ = this.select((s) => s.loading);
  private date$ = this.select((s) => s.date);
  private mode$ = this.select((s) => s.mode);
  private visibleMonthCompactCalendar$ = this.select(
    (s) => s.visibleMonthCompactCalendar
  );
  private firstDayOfWeek$ = this.select((s) => s.firstDayOfWeek);
  private lastDayOfWeek$ = this.select((s) => s.lastDayOfWeek);
  private scheduleId$ = this.select((s) => s.scheduleId);
  private filterActive$ = this.select((s) => s.filterActive);
  private datelocked$ = this.select((s) => s.datelocked);
  private skipForRange$ = this.select((state) => state.skipForRange);
  private defaultFilter$ = this.store.defaultFilter$;
  private params$ = this.activatedRoute.queryParams.pipe(
    map((params) => params)
  );

  get weekDayNames() {
    return this.dateAdapter.getWeekdaysShort(WeekDay.Monday);
  }

  employees = toSignal(this.employees$);
  $filter = this.store.$filter;
  breakpoint$ = this.responsive.breakpoint$;
  loading = toSignal(this.loading$);
  response = toSignal(this.store.response$);
  $maxHits = toSignal(this.store.maxHits$);
  schedules = toSignal(this.schedules$);
  error = toSignal(this.store.error$);
  scheduleId = toSignal(this.scheduleId$);
  entries = toSignal(this.entries$);
  flatEntries = computed(() =>
    Object.entries(this.entries()).flatMap((e) => e[1])
  );
  date = toSignal(this.date$);
  mode = toSignal(this.mode$);
  visibleMonthCompactCalendar = toSignal(this.visibleMonthCompactCalendar$);
  firstDayOfWeek = toSignal(this.firstDayOfWeek$);
  lastDayOfWeek = toSignal(this.lastDayOfWeek$);
  filterActive = toSignal(this.filterActive$);
  $queryParams = this.store.$queryParams;
  datelocked = toSignal(this.datelocked$);
  $params = toSignal(this.params$);
  $defaultFilter = toSignal(this.defaultFilter$);

  screenWidth$ = this.responsive.sizeChanged$.pipe(
    takeUntilDestroyed(this.destroyRef),
    map(() => window.innerWidth)
  );
  weekDateEnd = this.dateAdapter.getLastDateOfWeek(this.date());
  loadMore: boolean = false;
  rows = signal<ContributorInfoDto[]>([]);

  $filteredOrganizationalUnits: WritableSignal<OrganizationalUnitDto[]> =
    signal([]);

  employeesEffect = effect(() => {
    const employees = this.employees();
    untracked(() => {
      this.rows.update((rows) => {
        // Add employees to rows
        rows = [];
        for (const employee of employees) {
          rows.push(employee);
        }

        // If rows are less than 10, fill with null objects
        while (rows.length < 16) {
          rows.push(null);
        }

        return rows;
      });
    });
  });

  defaultFilterEffect = effect(() => {
    const defaultFilter = this.$defaultFilter();
    const filter = this.$filter();
    if (!isEmpty(defaultFilter) && isEmpty(filter) && isEmpty(this.$params())) {
      untracked(() => {
        this.initDefaultSetups(defaultFilter);
      });
    }
  });

  loadQueryEffect = effect(() => {
    const filter = this.$filter();
    const defaultFilter = this.$defaultFilter();

    untracked(() => {
      if (!isEmpty(filter)) {
        this.querySchedules();

        if (
          !isEqual(
            this.createFilter(filter).getQueryParams(),
            this.createFilter(defaultFilter).getQueryParams()
          )
        ) {
          this.patchState({ filterActive: true });
        }
      }
    });
  });

  queryParamsEffect = effect(() => {
    const queryParams = this.$queryParams();

    if (!isEmpty(queryParams)) {
      untracked(() => {
        this.navigate(queryParams);
      });
    }
  });

  get isInfoRole() {
    return this._session.isInfo;
  }

  constructor(
    private responsive: ResponsiveService,
    private dateAdapter: DateAdapter,
    public dateService: DateService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    super({
      date: new Date(),
      entries: {},
      schedules: [],
      employees: [],
      mode: 'month',
      visibleMonthCompactCalendar: new Date(),
      firstDayOfWeek: new Date(),
      lastDayOfWeek: new Date(),
      loading: true,
      scheduleId: '',
      filterActive: false,
      queryParams: undefined,
      datelocked: false,
      skipForRange: {},
    });
    this.store.clearData();
    this.store.queryFilter();

    effect(() => {
      const queryParams = this.$params();
      if (!isEmpty(queryParams) && !isEmpty(this.$defaultFilter())) {
        untracked(async () => {
          this.updateFilterValues(queryParams);

          const ous =
            await this.calendarFilterService.getFilteredOrganizationalUnitsByStrings(
              queryParams.filter_ou.split(';')
            );
          this.$filteredOrganizationalUnits.set(ous);
        });
      }
    });
  }

  initDefaultSetups(defaultFilter: QuerySettingsDto) {
    const filter = this.createFilter(defaultFilter);
    this.store.updateFilter(filter);
    this.setToday();
    this.setMode(this.breakpoint$.getValue() === 'mobile' ? 'day' : 'month');

    this.setSelectedOus(filter.getQueryParams().filter_ou);

    this.initializeScheduleLogic();
  }

  initializeScheduleLogic() {
    this.store.response$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((response) => {
        this.patchState({ schedules: response });
      });

    // Build calendar entries for ui from schedules response
    this.schedules$
      .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(100))
      .subscribe((response) => {
        if (this.error() === null) {
          this.buildEntries(response);
          setTimeout(() => this.patchState({ loading: false }), 200);
        }
      });

    combineLatest([this.date$, this.mode$])
      .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(500))
      .subscribe(([date, mode]) => {
        this.patchState({ loading: true });
      });
  }

  updateFilterValues(params: Params) {
    const filter = this.createFilter(this.$defaultFilter());
    filter.fromQueryParams({
      main_qs: undefined,
      filter_ou: params.filter_ou,
    } as Params);
    this.store.updateFilter(filter as QuerySettingsDto);
    if (params.mode) {
      this.setMode(params.mode);
    }
    if (params.date) {
      this.setSelectedDate(new Date(params.date));
    }

    if (params.filter_ou) {
      this.setSelectedOus(params.filter_ou);
    }

    this.initializeScheduleLogic();
  }

  buildEntries(response: any) {
    let employees = this.loadMore ? this.employees() : [];
    let calendarEntries = {};
    for (const res of response) {
      if (res?.schedules && Array.isArray(res?.schedules)) {
        const entries = res.schedules?.flatMap((sched) =>
          sched.items?.map((item) =>
            this.createCalendarEntry(item, res.contributor)
          )
        );

        if (!employees.find((e) => e.uId === res.contributor.uId)) {
          employees = [...employees, res.contributor].sort((a, b) =>
            a.lastName?.localeCompare(b.lastName)
          );
        }

        //entries nur hinzufügen, wenn scheduleitems vorhanden sind
        if (res?.schedules.find((_) => true).hasOwnProperty('items')) {
          calendarEntries[res.contributor.uId] = entries;
        }
      }
    }
    this.patchState({
      employees,
      entries: { ...(this.loadMore ? this.entries() : {}), ...calendarEntries },
    });
  }

  createCalendarEntry(item: ScheduleItemInfoDto, employee: ContributorInfoDto) {
    return {
      id: item.uId || '',
      start: new Date(item.start),
      end: new Date(item.stop),
      item: item,
      employee,
    };
  }

  async loadMoreSchedules() {
    if (this.loading()) {
      return;
    }
    this.patchState({ loading: true });
    const skipForRange = await firstValueFrom(this.skipForRange$);
    const { start, end } = this.getDateRange();
    let skip =
      skipForRange[`${start.toDateString()}-${end.toDateString()}`] || 0;
    //nur nachladen, wenn noch nicht alles geladen wurde in diesem Zeitraum
    if (skip < this.$maxHits() && this.rows().length < this.$maxHits()) {
      skip += 20;
      this.patchSkipForRange(skipForRange, start, end, skip);
      this.querySchedules(skip);
    } else {
      this.patchState({ loading: false });
    }
  }

  querySchedules(skip: number = 0) {
    this.loadMore = skip > 0;
    this.store.query(this.date(), this.mode(), skip);
  }

  modeChanged(mode: CalendarMode) {
    this.setMode(mode);
  }

  dateChanged(date: Date) {
    this.setSelectedDate(date);
  }

  dateLockedDayChanged(datelocked: boolean) {
    this.patchState({ datelocked });
  }

  visibleMonthCompactCalendarChanged(date: Date) {
    this.patchState({ visibleMonthCompactCalendar: date });
  }

  navigate(params: Params) {
    this.router.navigate([], {
      queryParams: { ...this.activatedRoute.snapshot.queryParams, ...params },
    });
  }

  updateQueryParams(params: Params) {
    this.store.updateQueryParams(params);
  }

  navigateDays(days: number) {
    this.patchState({ loading: true });
    const selectedDate = this.dateAdapter.addCalendarDays(this.date(), days);
    this.setMode(this.mode(), false, selectedDate);
    if (this.mode() !== 'week') {
      this.setSelectedDate(selectedDate);
    } else {
      this.updateWeekMode(selectedDate);
    }
  }

  setToday() {
    this.setSelectedDate(this.dateAdapter.today());
  }

  setSelectedDate(date: Date) {
    this.patchState({ date });
    this.updateQueryParams({ date: date.toISOString() });
  }

  setSelectedOus(ousString: string) {
    this.updateQueryParams({ filter_ou: ousString });
  }

  setCurrentWeek() {
    const today = new Date();
    const selectedDate = this.dateAdapter.getFirstDateOfWeek(
      today,
      WeekDay.Monday
    );
    this.weekDateEnd = this.dateAdapter.addCalendarDays(today, 6);
    this.setSelectedDate(selectedDate);
  }

  setMode(
    mode: CalendarMode,
    fromUi: boolean = false,
    date: Date = this.date()
  ) {
    this.handleModeTransitions(mode, date);

    this.patchState({ mode });

    // Reset entries and employees if triggered from UI
    if (fromUi) {
      this.resetEntriesAndEmployees();
    }
    this.updateQueryParams({ mode });
  }

  private handleModeTransitions(mode: CalendarMode, date: Date): void {
    if (mode === 'week') {
      this.updateWeekMode(date);
    } else if (
      mode === 'day' &&
      this.mode() === 'month' &&
      !this.datelocked()
    ) {
      let selectedDate = this.dateAdapter.getFirstDateOfMonth(date);

      if (
        moment(selectedDate).isSame(moment(), 'month') &&
        moment(selectedDate).isSame(moment(), 'year')
      ) {
        //if today is in the same month go to today
        selectedDate = moment().toDate();
      }
      if (this.dateAdapter.getDayOfWeek(selectedDate) === 0) {
        //if selectedDate is a sunday, go to the next monday
        this.setSelectedDate(this.dateAdapter.addCalendarDays(selectedDate, 1));
      } else {
        this.setSelectedDate(selectedDate);
      }
    }
  }

  private updateWeekMode(date: Date): void {
    const selectedDate = this.dateAdapter.getFirstDateOfWeek(
      date,
      WeekDay.Monday
    );
    this.weekDateEnd = this.dateAdapter.addCalendarDays(selectedDate, 6);
    this.setSelectedDate(selectedDate);
  }

  onCompactCalendar(date: Date) {
    this.setSelectedDate(date);
  }

  clearCalendarEntries() {
    this.patchState({ entries: {} });
  }

  filterClosed({ active, reload }: { active: boolean; reload: boolean }) {
    this.patchState({ filterActive: active, loading: reload });
  }

  updateSchedules(schedules: ContributorScheduleDto[]) {
    this.patchState({ schedules });
  }

  // Helper to get date range based on the mode (month/week/day)
  private getDateRange(): { start: Date; end: Date } {
    const start = this.date();
    let stop;

    if (this.mode() === 'month') {
      // Extend the month range for calendar view (get full weeks)
      stop = this.dateService.getExtendedMonth({ selectedDate: start }).stop;
    } else {
      stop = this.dateService.getTimespan({
        selectedDate: start,
        mode: this.mode(),
      });
    }

    const end = this.dateAdapter.addCalendarDays(start, stop);
    return { start, end };
  }

  private patchSkipForRange(
    skipForRange: any,
    start: Date,
    end: Date,
    skip: number
  ): void {
    this.patchState({
      skipForRange: {
        ...skipForRange,
        [`${start.toDateString()}-${end.toDateString()}`]: skip,
      },
    });
  }

  private resetEntriesAndEmployees(): void {
    this.patchState({ entries: {}, employees: [], loading: true });
  }

  private createFilter(filter: QuerySettingsDto): Filter {
    return Filter.create(filter as IFilter);
  }
}
