@if ((breakpoint$ | async) === 'mobile') {
<calendar-stepper
  class="bg-hrp-subtle-100"
  [mode]="mode()"
  [selectedDate]="date()"
  [enableDateLocked]="false"
  [displayRange]="['day', 'week', 'month']"
  [groupCalendar]="true"
  (navigateDays)="navigateDays($event)"
  (modeChanged)="setMode($event, true)"
  (dateChanged)="setSelectedDate($event)"
></calendar-stepper>

<div class="flex flex-col">
  <ng-container>
    <calendar-navigation-mobile-week
      *ngIf="(breakpoint$ | async) === 'mobile' && mode() === 'day'"
      [selectedDate]="date()"
      (navigateDays)="navigateDays($event)"
      (selectDay)="setSelectedDate($event)"
    ></calendar-navigation-mobile-week>
    <calendar-group-body-day
      *ngIf="mode() === 'day'"
      [date]="date()"
      [loading]="loading()"
      [entries]="flatEntries()"
      [employeeList]="rows()"
      [schedules]="schedules()"
      (loadMoreSchedules)="loadMoreSchedules()"
      (updateSchedules)="updateSchedules($event)"
    ></calendar-group-body-day>
    <calendar-group-body-week
      *ngIf="mode() === 'week'"
      [date]="date()"
      [entries]="flatEntries()"
      [loading]="loading()"
      [employeeList]="rows()"
      (dateChanged)="setSelectedDate($event)"
      (modeChanged)="setMode($event, true)"
      (loadMoreSchedules)="loadMoreSchedules()"
    ></calendar-group-body-week>
    <calendar-group-body-month
      *ngIf="mode() === 'month'"
      [date]="date()"
      [entries]="flatEntries()"
      [loading]="loading()"
      [employeeList]="rows()"
      (dateChanged)="setSelectedDate($event)"
      (modeChanged)="setMode($event, true)"
      (loadMoreSchedules)="loadMoreSchedules()"
    ></calendar-group-body-month>
  </ng-container>
</div>

<div class="fixed w-full h-16 bottom-1 flex items-center px-4 z-[350]">
  <div
    data-which="mobile-actions"
    class="bg-hrp-subtle-200 w-full h-full flex justify-around items-center fixed-footer-widget shadow-card-2"
  >
    <button
      data-what="breaks-button"
      *ngIf="mode() !== 'month' && !isInfoRole"
      type="button"
      class="flex flex-col items-center w-[4.5rem] text-hrp-primary-700 hrp-text-body-xs-emph"
      (click)="
        calendarGroupBreakTimeDayService.openBreakTimeDialog({ mobile: true })
      "
    >
      <span class="material-symbols-rounded">local_cafe</span>
      Pausen
    </button>
    <button
      data-what="filter-button"
      type="button"
      class="flex flex-col items-center w-[4.5rem] text-hrp-primary-700 hrp-text-body-xs-emph"
      (click)="filterOverlay.open()"
    >
      <span class="material-symbols-rounded">filter_list</span>
      {{ "users.filter" | transloco }}
    </button>
  </div>
</div>

} @else if ((breakpoint$ | async) === 'tablet') {

<calendar-stepper
  class="bg-hrp-subtle-100 pl-6"
  [mode]="mode()"
  [selectedDate]="date()"
  [enableDateLocked]="false"
  [displayRange]="['day', 'week', 'month']"
  [groupCalendar]="true"
  (navigateDays)="navigateDays($event)"
  (modeChanged)="setMode($event, true)"
  (dateChanged)="setSelectedDate($event)"
>
  <button
    data-which="tablet-actions"
    data-what="filter-button"
    type="button"
    class="tertiary w-auto self-end mr-2"
    (click)="filterOverlay.open()"
    [class.active]="filterActive()"
  >
    <div
      class="flex flex-row items-center tertiary small rounded-md"
      [class.active]="filterActive()"
    >
      <span class="material-symbols-rounded mr-1">filter_list</span>
      {{ "users.filter" | transloco }}
    </div>
  </button>
</calendar-stepper>

<div class="flex flex-row gap-1">
  <ng-container>
    <calendar-group-body-day
      *ngIf="mode() === 'day'"
      [date]="date()"
      [entries]="flatEntries()"
      [loading]="loading()"
      [employeeList]="rows()"
      [schedules]="schedules()"
      (loadMoreSchedules)="loadMoreSchedules()"
      (updateSchedules)="updateSchedules($event)"
    ></calendar-group-body-day>
    <calendar-group-body-week
      *ngIf="mode() === 'week'"
      [date]="date()"
      [entries]="flatEntries()"
      [loading]="loading()"
      [employeeList]="rows()"
      (dateChanged)="setSelectedDate($event)"
      (modeChanged)="setMode($event, true)"
      (loadMoreSchedules)="loadMoreSchedules()"
    ></calendar-group-body-week>
    <calendar-group-body-month
      *ngIf="mode() === 'month'"
      [date]="date()"
      [entries]="flatEntries()"
      [loading]="loading()"
      [employeeList]="rows()"
      (dateChanged)="setSelectedDate($event)"
      (modeChanged)="setMode($event, true)"
      (loadMoreSchedules)="loadMoreSchedules()"
    ></calendar-group-body-month>
  </ng-container>
</div>

} @else {
<div class="flex flex-col bg-hrp-subtle-200 h-full gap-3">
  <div
    class="flex flex-col h-24 justify-between w-screen items-center sticky left-0 right-0 z-20"
  >
    <div class="flex flex-row w-full gap-1 justify-between h-24 items-stretch">
      <calendar-group-navigation
        [mode]="mode()"
        [selectedDate]="date()"
        (selectedDateChanged)="dateChanged($event)"
        (dateLocked)="dateLockedDayChanged($event)"
        (dateLockedValueChanged)="(false)"
      >
      </calendar-group-navigation>
      <calendar-stepper
        [mode]="mode()"
        [selectedDate]="date()"
        [enableDateLocked]="true"
        (dateLocked)="dateLockedDayChanged($event)"
        [displayRange]="['day', 'week', 'month']"
        [groupCalendar]="true"
        (navigateDays)="navigateDays($event)"
        (modeChanged)="setMode($event, true)"
        (dateChanged)="setSelectedDate($event)"
      ></calendar-stepper>
      <div class="w-full flex justify-end flex-col pr-6 h-24">
        <div
          data-which="desktop-actions"
          class="flex justify-end items-center h-12"
        >
          <button
            data-what="filter-button"
            type="button"
            class="tertiary w-auto self-end mr-2"
            (click)="filterOverlay.open()"
            [class.active]="filterActive()"
          >
            <div class="flex flex-row items-center small rounded-md">
              <span class="material-symbols-rounded mr-1">filter_list</span>
              {{ "users.filter" | transloco }}
            </div>
          </button>
        </div>
      </div>
    </div>

  </div>

  <horizontal-scroll class="mx-auto w-[calc(100vw-3rem)] h-6" justify="start">
    <ng-container *ngIf="$filteredOrganizationalUnits(); let ous">
      @for(ou of ous; track ou.uId) {
        <chip size="small">
          {{ ou.name }}
        </chip>
      }
    </ng-container>
  </horizontal-scroll>

  <div class="flex flex-row items-center w-screen">
    <div class="flex flex-col items-center shrink grow w-full px-6">
      <ng-container>
        <calendar-group-body-day
          *ngIf="mode() === 'day'"
          [date]="date()"
          [entries]="flatEntries()"
          [employeeList]="rows()"
          [schedules]="schedules()"
          [loading]="loading()"
          (updateSchedules)="updateSchedules($event)"
          (loadMoreSchedules)="loadMoreSchedules()"
        >
        </calendar-group-body-day>
        <calendar-group-body-week
          *ngIf="mode() === 'week'"
          [date]="date()"
          [entries]="flatEntries()"
          [employeeList]="rows()"
          [loading]="loading()"
          (dateChanged)="setSelectedDate($event)"
          (modeChanged)="setMode($event, true)"
          (loadMoreSchedules)="loadMoreSchedules()"
        ></calendar-group-body-week>
        <calendar-group-body-month
          *ngIf="mode() === 'month'"
          [date]="date()"
          [entries]="flatEntries()"
          [employeeList]="rows()"
          [loading]="loading()"
          (dateChanged)="setSelectedDate($event)"
          (modeChanged)="setMode($event, true)"
          (loadMoreSchedules)="loadMoreSchedules()"
        ></calendar-group-body-month>
      </ng-container>
    </div>
  </div>
</div>
}

<par-progress-spinner
  *ngIf="loading()"
  class="calendar-spinner"
  size="54"
  mode="indeterminate"
></par-progress-spinner>

<hrp-filter-overlay #filterOverlay>
  <ng-container *ngIf="$filter(); let filter">
    <calendar-group-filter
      *ngIf="filterOverlay.isOpen"
      [filterSettings]="filter"
      (closeFilterOverlayEvent)="filterClosed($event); filterOverlay.close()"
    ></calendar-group-filter>
  </ng-container>
</hrp-filter-overlay>
